<template>
  <div class="ma-3 vuse-user-profile">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <help class="help--breadcrumb" :helpId="43" />
    </v-row>
    <v-card color="neu-glow-inset with-radius">
      <cover
        :avatar="user.avatar ? user.avatar.route : null"
        :cover="identity.cover ? identity.cover.route : null"
      />
      <v-row class="mt-1 mb-1 mx-0">
        <v-col class="align-self-center ml-12 pa-0" align="start">
          <h4 class="text-h5 pb-1">
            {{ user.name }} {{ user.position ? ' - ' + user.position.name : '' }}
          </h4>
        </v-col>
        <v-col class="pa-0">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            right
            class="vuse-neu-ele"
          >
            <v-tabs-slider color="primary" />
            <v-tab v-for="(item, index) in items" :key="index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card>
    <v-sheet class="transparent profile-tab-item">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="index" dark>
          <keep-alive>
            <component
              :is="item.component"
              :user="user"
              @reloadUser="getUser()"
              :loadingUser="loadingUser"
            />
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>
<script>
  import Cover from '@/components/CommonForModules/Cover'
  import Profile from '@/components/HumanTalent/Profile'
  import { decryptData } from '@/utils/encryption'
  export default {
    components: {
      Cover,
      Profile,
    },
    data() {
      return {
        tab: 0,
        userId: 0,
        items: [
          {
            component: 'Profile',
            name: 'Perfil',
          },
        ],
        identity: {},
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Perfil de Personal',
            disabled: true,
            href: '',
          },
        ],
        user: {},
        loadingUser: false,
        loadingIdentity: false,
      }
    },
    created() {
      this.getUserId()
      this.getUser()
      this.getIdentity()
    },
    methods: {
      getUserId() {
        if (this.$route.name === 'administrative/human-talent/UserProfile') {
          this.userId = parseInt(this.$route.params.userId)
        } else if (this.$route.name === 'administrative/human-talent/Profile') {
          if (this.$session.get('userId')) {
            this.userId = parseInt(decryptData(this.$session.get('userId')))
          }
        }
      },
      getUser() {
        this.loadingUser = true

        this.$axios
          .get(
            'users/' +
              this.userId +
              '?with[]=avatar&with[]=position&with[]=uploads&with[]=countries&with[]=module&with[]=userInformation'
          )
          .then((response) => {
            this.user = response.data
            this.loadingUser = false
          })
      },
      getIdentity() {
        this.loadingIdentity = true
        this.$axios
          .get('identities?limit=1&order=created_at&by=desc')
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.identity = response.data[0]
            }
            this.loadingIdentity = false
          })
      },
    },
  }
</script>
<style lang="scss">
  .v-breadcrumbs__item {
    font-size: 12px;
  }
  .v-breadcrumbs li:nth-child(even) {
    padding: 0 5px;
  }
  .v-breadcrumbs {
    padding: 5px 10px !important;
  }
</style>
