export function handleActivityEn(activity) {
  const model = `${getModelEn(activity.subject_type)} #${activity.subject_id}`
  const action = `Action performed: ${getDescriptionEn(activity.description)}`
  const id = `Identifier = ${activity.id}`
  const timestamp = activity.created_at
  const changes = activity.changes
  return { id, model, action, timestamp, changes}

}

export function handleActivityEs(activity) {
  const model = `${getModelEs(activity.subject_type)} #${activity.subject_id}`
  const action = `Acción realizada: ${getDescriptionEs(activity.description)}`
  const id = `Identificador: ${activity.id}`
  const timestamp = activity.created_at
  const changes = activity.changes
  return { id, model, action, timestamp, changes}

}

function getModelEn(modelPath) {
  switch (modelPath) {
    // case 'App\\Models\\Activity': return ''
    case 'App\\Models\\Answer': return 'Answer'
    // case 'App\\Models\\AppliedEvaluation': return ''
    case 'App\\Models\\AssignedEvaluation': return ''
    case 'App\\Models\\Category': return 'Category'
    case 'App\\Models\\Collaborator': return 'Collaborator'
    case 'App\\Models\\Company': return 'Company'
    case 'App\\Models\\CompanyDocument': return 'Company Document'
    case 'App\\Models\\CompanyModule': return 'Modules Asignation to Company'
    case 'App\\Models\\CompanyPenalty': return 'Company Penalty'
    case 'App\\Models\\CompanyType': return 'Company Type Asignation'
    case 'App\\Models\\Content': return 'Manual Content'
    case 'App\\Models\\Country': return 'Country'
    case 'App\\Models\\Course': return 'Course'
    // case 'App\\Models\\Database': return ''
    case 'App\\Models\\Document': return 'Documento'
    case 'App\\Models\\Evaluation': return 'Evaluación'
    case 'App\\Models\\Exam': return 'Exam'
    // case 'App\\Models\\ExamUser': return ''
    case 'App\\Models\\FunctionalArea': return 'Functional Area'
    // case 'App\\Models\\Graphic': return ''
    // case 'App\\Models\\GraphicGrid': return ''
    // case 'App\\Models\\GraphicLevel': return ''
    case 'App\\Models\\Gideline': return 'Criteria of a point to evaluate'
    case 'App\\Models\\Identity': return 'Corporate identity'
    case 'App\\Models\\Lesson': return 'Lesson'
    case 'App\\Models\\LessonComment': return 'Commentary on a lesson'
    case 'App\\Models\\Level': return 'Level'
    case 'App\\Models\\Manual': return 'Manual'
    case 'App\\Models\\Metric': return 'Metric'
    case 'App\\Models\\MetricPosition': return 'Metrics Asignation to Position'
    case 'App\\Models\\MetricPositionUser': return 'Metrics Asignation to Position`s users'
    case 'App\\Models\\Module': return 'Module'
    case 'App\\Models\\ModuleUser': return 'Modules Asignation to User'
    case 'App\\Models\\Movement': return 'Movement'
    case 'App\\Models\\MovementReason': return 'Movement Reason'
    //  case 'App\\Models\\Observation': return 'Observation'
    // case 'App\\Models\\PasswordReset': return ''
    case 'App\\Models\\Penalty': return 'Penalty'
    case 'App\\Models\\Phrase': return 'Phrase'
    case 'App\\Models\\Position': return 'Position'
        case 'App\\Models\\PositionSkill': return 'Position Skill'
    case 'App\\Models\\Process': return 'Process'
    case 'App\\Models\\Question': return 'Question'
        case 'App\\Models\\Request': return 'Supervision'
    case 'App\\Models\\RequestLog': return 'Action on a supervision'
    case 'App\\Models\\Requirement': return 'Point to evaluate'
    // case 'App\\Models\\Role': return ''
    case 'App\\Models\\Skill': return 'Skill'
    case 'App\\Models\\Strata': return 'Strata'
    case 'App\\Models\\Type': return 'Type'
    case 'App\\Models\\Upload': return 'Upload'
    case 'App\\Models\\User': return 'User'
    // case 'App\\Models\\UserGraphic': return ''
    case 'App\\Models\\UserInformation': return 'Information of a user'
    case 'App\\Models\\UserSkill': return 'User skill'
    case 'App\\Models\\Version': return 'Version'
    default: return '*'

  }
}

function getDescriptionEn(desc) {
  const strings = desc.split('_')
  switch (strings[0]) {
    case 'created': return 'Creation'
    case 'updated': return 'Update'
    case 'deleted': return 'Subtraction'
    default: return '*'

  }
}

function getModelEs(modelPath) {
  switch (modelPath) {
    // case 'App\\Models\\Activity': return ''
    case 'App\\Models\\Answer': return 'Respuesta de un examen'
    // case 'App\\Models\\AppliedEvaluation': return ''
    case 'App\\Models\\AssignedEvaluation': return 'Asignación de una evaluación'
    case 'App\\Models\\Category': return 'Categoría'
    case 'App\\Models\\Collaborator': return 'Colaborador'
    case 'App\\Models\\Company': return 'Empresa'
    case 'App\\Models\\CompanyDocument': return 'Documento de una empresa'
    case 'App\\Models\\CompanyModule': return 'Asignación de Módulos a una Empresa'
    case 'App\\Models\\CompanyPenalty': return 'Penalidad de una empresa'
    case 'App\\Models\\CompanyType': return 'Asignación de tipo de Empresa'
    case 'App\\Models\\Content': return 'Contenido de un manual'
    case 'App\\Models\\Country': return 'País'
    case 'App\\Models\\Course': return 'Curso'
    // case 'App\\Models\\Database': return ''
    case 'App\\Models\\Document': return 'Documento'
    case 'App\\Models\\Evaluation': return 'Evaluación'
    case 'App\\Models\\Exam': return 'Examen'
    // case 'App\\Models\\ExamUser': return ''
    case 'App\\Models\\FunctionalArea': return 'Área funcional'
    // case 'App\\Models\\Graphic': return ''
    // case 'App\\Models\\GraphicGrid': return ''
    // case 'App\\Models\\GraphicLevel': return ''
    case 'App\\Models\\Gideline': return 'Criterios de un punto a evaluar'
    case 'App\\Models\\Identity': return 'Identidad corporativa'
    case 'App\\Models\\Lesson': return 'Lección'
    case 'App\\Models\\LessonComment': return 'Comentario de una lección'
    case 'App\\Models\\Level': return 'Nivel'
    case 'App\\Models\\Manual': return 'Manual'
    case 'App\\Models\\Metric': return 'Métrica'
    case 'App\\Models\\MetricPosition': return 'Asignación de métricas a un cargo'
    case 'App\\Models\\MetricPositionUser': return 'Asignación de métricas a los usuarios de un cargo'
    case 'App\\Models\\Module': return 'Módulo'
    case 'App\\Models\\ModuleUser': return 'Asignación de Módulos a un Usuario'
    case 'App\\Models\\Movement': return 'Movimiento'
    case 'App\\Models\\MovementReason': return 'Razón de Movimientos'
    // case 'App\\Models\\Observation': return 'Observación'
    // case 'App\\Models\\PasswordReset': return ''
    case 'App\\Models\\Penalty': return 'Penalidad'
    case 'App\\Models\\Phrase': return 'Frase'
    case 'App\\Models\\Position': return 'Cargo'
    case 'App\\Models\\PositionSkill': return 'Compentencia de un cargo'
    case 'App\\Models\\Process': return 'Proceso'
    case 'App\\Models\\Question': return 'Pregunta'
    case 'App\\Models\\Request': return 'Supervisión'
    case 'App\\Models\\RequestLog': return 'Acción sobre una supervisión'
    case 'App\\Models\\Requirement': return 'Punto a evaluar'
    // case 'App\\Models\\Role': return ''
    case 'App\\Models\\Skill': return 'Competencia'
    case 'App\\Models\\Strata': return 'Estrato'
    case 'App\\Models\\Type': return 'Formato de empresa'
    // case 'App\\Models\\Upload': return 'Archivo multimedia'
    case 'App\\Models\\User': return 'Usuario'
    // case 'App\\Models\\UserGraphic': return ''
    case 'App\\Models\\UserInformation': return 'Información de un usuario'
    case 'App\\Models\\UserSkill': return 'Competencia de un usuario'
    case 'App\\Models\\Version': return 'Versión de un manual'
    default: return '*'

  }
}

function getDescriptionEs(desc) {
  const strings = desc.split('_')
  switch (strings[0]) {
    case 'created': return 'Creación'
    case 'updated': return 'Actualización'
    case 'deleted': return 'Sustracción'
    default: return '*'

  }
}
