<template>
  <v-card class="user-profile-list">
    <v-toolbar color="primary" light height="40">
      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer />
      <v-scroll-x-reverse-transition>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              v-show="!readonly"
              color="grey lighten-5"
              class="ma-1"
              @click="saveUserData()"
              :disabled="!valid"
            >
              <v-icon>{{
                valid ? 'mdi-cloud-upload' : 'mdi-cloud-upload-outline'
              }}</v-icon>
            </v-btn>
          </template>
          <span>Guardar</span>
        </v-tooltip>
      </v-scroll-x-reverse-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-bind="attrs"
            v-on="on"
            class="ma-1"
            color="white"
            v-if="editable"
            :disabled="!valid"
            @click="readonly = !readonly"
          >
            <v-icon>{{ readonly ? 'mdi-pencil' : 'mdi-pencil' }} </v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>
    </v-toolbar>
    <v-divider />
    <v-card-text v-if="text">
      <div class="text-body-2">{{ text }}</div>
    </v-card-text>
    <v-form v-model="valid">
      <v-row class="mx-2 my-6" align="center">
        <v-col cols="12" sm="6" class="d-flex align-center">
          <v-avatar size="100" rounded="50%" class="mx-auto">
            <v-img :src="avatar.route" contain v-if="avatar" />
            <v-icon size="100" v-else>mdi-account-circle</v-icon>
          </v-avatar>
          <v-file-input
            dense
            label="Avatar"
            v-model="avatarFile"
            :readonly="readonly"
            filled
            v-if="!readonly"
            :rules="[rules.fileSize]"
            accept=".png,.jpg,.jpeg,.webp,.avif"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="username">
          <v-text-field
            dense
            prepend-icon="mdi-account-circle-outline"
            label="Usuario"
            v-model="username"
            readonly
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="name || name == ''">
          <v-text-field
            dense
            prepend-icon="mdi-account-circle-outline"
            label="Name"
            v-model="name"
            :readonly="readonly"
            :filled="!readonly"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="lastname || lastname == ''">
          <v-text-field
            dense
            prepend-icon="mdi-account-circle-outline"
            label="Apellido"
            v-model="lastname"
            :readonly="readonly"
            :filled="!readonly"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col cols="12" sm="6" v-if="email || email == ''">
          <v-text-field
            dense
            prepend-icon="mdi-email-outline"
            label="Email"
            v-model="email"
            readonly
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="phone || phone == ''">
          <v-text-field
            dense
            prepend-icon="mdi-phone"
            label="Teléfono"
            v-model="phone"
            :readonly="readonly"
            :filled="!readonly"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="date">
          <v-text-field
            dense
            prepend-icon="mdi-calendar"
            label="Fecha de ingreso al cargo"
            v-model="date"
            type="date"
            :readonly="readonly"
            :filled="!readonly"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" v-if="lastname || lastname == ''">
          <v-row class="ma-0" align="center">
            <label class="mr-1">País</label>
            <multiselect
              style="width: 80%"
              track-by="id"
              placeholder="País"
              :custom-label="countryName"
              v-model="country"
              :options="countries"
              :multiple="false"
              :loading="loadingCountries"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="false"
              :disabled="readonly"
            />
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <label class="mr-1">Módulos</label>
          <multiselect
            style="width: 80%"
            track-by="id"
            label="name"
            placeholder="Seleccionar"
            v-model="selectedModules"
            :options="modules"
            :multiple="true"
            disabled
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Contraseña"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.min]"
            v-model="password"
            counter
            :color="password === confirmPassword ? 'success' : 'danger'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            v-if="!readonly"
            :filled="!readonly"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Confirmar contraseña"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.min]"
            v-model="confirmPassword"
            counter
            :color="password === confirmPassword ? 'success' : 'danger'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            v-if="!readonly"
            :filled="!readonly"
          />
        </v-col>
      </v-row>
    </v-form>
    <!-- <div v-if="items">
      <v-list-item
        two-line
        v-for="(item, index) in items"
        :key="`${title}-twolistitem-${index}`"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon :color="item.iconColor ? item.iconColor : 'primary'">{{
            item.icon
          }}</v-icon>
        </v-list-item-icon>
        <v-list-item-avatar v-if="item.avatar">
          <v-img :src="item.avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle v-html="item.subtitle" />
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div> -->
  </v-card>
</template>
<script>
  export default {
    name: 'UserProfileData',
    props: {
      title: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        default: null,
      },
      user: {
        type: Object,
        default: null,
      },
      editable: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        readonly: true,
        valid: false,
        avatar: null,
        avatarFile: null,
        username: '',
        name: '',
        lastname: '',
        country: {},
        countries: [],
        modules: [],
        selectedModules: [],
        email: '',
        phone: '',
        date: '',
        password: '',
        confirmPassword: '',
        showPassword: false,

        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 6 : true) || '6 caracteres como mínimo',
          // max: (v) =>
          //   (v ? v.length <= 400 : true) || 'Debe poser menos de 400 caracteres',
          fileSize: (v) =>
            !v || v.size < 5120000 || 'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    created() {
      this.getCountries()
      if (this.user) {
        this.handleUserData(this.user)
      }
    },
    watch: {
      user(newValue) {
        this.handleUserData(newValue)
      },
    },
    methods: {
      getCountries() {
        this.loadingCountries = true
        this.$axios.get('countries?limit=250').then((response) => {
          this.countries = this.countries.concat(response.data)
          this.loadingCountries = false
        })
      },
      handleUserData(user) {
        this.avatar = user.avatar
        this.name = user.name
        this.lastname = user.lastname
        this.username = user.username
        this.country = user.countries.shift()
        this.email = user.email
        this.phone = user.phone
        this.selectedModules = user.module
        this.date = user.position_assigned_at
      },
      saveUserData() {
        const data = {}

        if (this.avatarFile) {
          data.file = this.avatarFile
          data.fileType = 'avatar'
        }
        if (this.name !== this.user.name) {
          data.name = this.name
        }
        if (this.lastname !== this.user.lastname) {
          data.lastname = this.lastname
        }
        // if (this.email !== this.user.email) {
        //   data.email = this.email
        // }
        if (this.phone !== this.user.phone) {
          data.phone = this.phone
        }
        if (this.country !== this.user.countries[0]) {
          data.countries = [this.country.id]
        }
        if (this.password !== '') {
          data.password = this.password
        }
        if (this.confirmPassword !== '') {
          data.confirmPassword = this.confirmPassword
        }
        if (
          data.name ||
          data.lastname ||
          data.phone ||
          data.countries ||
          data.avatarFile
        ) {
          this.$axios
            .patch('users/' + this.user.id, data)
            .then(((this.readonly = !this.readonly), this.$emit('reloadUser')))
        } else {
          this.readonly = !this.readonly
        }
      },
      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },
    },
  }
</script>
<style lang="scss">
  .user-profile-list {
    .theme--light.v-btn.v-btn--disabled,
    .theme--light.v-btn.v-btn--disabled .v-icon,
    .theme--light.v-btn.v-btn--disabled .v-btn__loading {
      color: #bdbdbd !important;
    }
  }
</style>
