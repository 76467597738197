<template>
  <v-row>
    <v-col cols="12" md="6">
      <user-profile-data
        :user="user"
        v-if="user && user.name"
        title="Datos personales"
        :editable="true"
        @reloadUser="$emit('reloadUser')"
      />
      <documents-upload
        :complement="false"
        :show-toolbar="true"
        :show-inputs="false"
        :item="user"
        :loading="loadingUser"
        :save-url="`users/${this.user.id}`"
        @reloadItem="$emit('reloadUser')"
      />
      <movements class="mt-4" :userId="user.id" />
    </v-col>
    <!-- <v-col cols="12" md="6">
    </v-col> -->
    <!-- <v-col cols="12" md="6">
      <movements :userId="user.id" />
    </v-col> -->
    <v-col cols="12" md="6">
      <last-activities class="neu-glow-inset" :user="user" :key="index" />
    </v-col>
  </v-row>
</template>

<script>
  import UserProfileData from '@/components/UI/List/UserProfileData'
  import DocumentsUpload from '@/components/CommonForModules/DocumentsUpload'
  import LastActivities from '@/components/CommonForModules/LastActivities'
  import Movements from '@/components/HumanTalent/MovementsTimeline'

  export default {
    components: {
      UserProfileData,
      DocumentsUpload,
      LastActivities,
      Movements,
    },
    props: { user: Object, loadingUser: Boolean },
    data() {
      return {
        index: 0,
      }
    },
    watch: {
      user() {
        this.index++
      },
    },
    computed: {
      overview() {
        if (this.user.name) {
          return {
            title: 'Datos personales',
            icon: 'info',
            items: [
              // {
              //   title: this.user.document_number,
              //   subtitle: 'DNI',
              //   icon: 'mdi-card-account-details',
              //   iconColor: 'blue',
              // },
              {
                title: this.user.name + ' ' + this.user.lastname,
                subtitle: 'Nombre',
                icon: 'mdi-account-circle-outline',
                iconColor: 'blue',
              },
              {
                title: this.user.email,
                subtitle: 'Email',
                icon: 'mdi-email-outline',
                iconColor: 'blue',
              },
              {
                title: this.user.phone,
                subtitle: 'Telefono',
                icon: 'mdi-phone',
                iconColor: 'blue',
              },
              {
                title: this.user.created_at.split('T')[0],
                subtitle: 'Fecha de bienvenida',
                icon: 'mdi-calendar',
                iconColor: 'blue',
              },
              // {
              //   title: this.user.local_phone,
              //   subtitle: 'Telefono Local',
              //   icon: 'mdi-phone-outline',
              //   iconColor: 'blue',
              // },
              // {
              //   title: this.user.birthDate,
              //   subtitle: 'Fecha de Nacimiento',
              //   icon: 'mdi-cake-variant',
              //   iconColor: 'blue',
              // },
            ],
          }
        } else {
          return {}
        }
      },
    },
    methods: {},
  }
</script>
