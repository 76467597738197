<template>
  <v-card class="pa-3" style="height: fit-content">
    <h4>{{ `${title} de ${userFullName}` }}</h4>
    <span class="caption">
      {{ description }}
    </span>

    <v-spacer />
    <v-list three-line class="transparent" v-if="activities.length > 0">
      <v-data-table
        :items-per-page="10"
        group-by="date"
        class="elevation-1"
        :items="activities"
        dense
        style="max-height: 500px; overflow: auto"
      >
        <template v-slot:[`group.header`]="{ group }">
          <th colspan="2">
            {{ group }}
          </th>
        </template>
        <template v-slot:item="{ item }">
          <v-card class="my-1">
            <v-list-item disabled dense>
              <v-list-item-avatar size="50">
                <img v-if="avatar && avatar.route" :src="avatar.route" />
              </v-list-item-avatar>
              <v-list-item-content class="mx-2 py-2" style="font-size: 0.8rem">
                <v-list-item-title v-html="item.model" />
                <v-list-item-subtitle v-html="item.action" />
                <v-list-item-subtitle v-html="item.id" />
                <v-list-item-subtitle
                  v-html="'Hora: ' + item.timestamp.split('T')[1].split('.')[0]"
                />
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
      </v-data-table>
    </v-list>
    <v-list three-line class="transparent" v-else>
      <template v-for="i in 10">
        <v-skeleton-loader :key="i" v-bind="attrs" type="list-item-avatar, divider" />
      </template>
    </v-list>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { handleActivityEs, handleActivityEn } from '@/mixins/Activities'
  import { decryptData } from '@/utils/encryption'

  export default {
    props: { user: Object },
    data() {
      return {
        attrs: {
          class: 'mb-1',
          boilerplate: false,
          elevation: 1,
        },
        data: [],
      }
    },
    watch: {
      user() {
        this.getActivities()
      },
    },
    computed: {
      avatar() {
        if (this.user && this.user.avatar) {
          return this.user.avatar
        } else {
          return decryptData(this.$session.get('userAvatar'))
        }
      },
      userFullName() {
        if (this.user.name && this.user.lastname) {
          return this.user.name + ' ' + this.user.lastname
        } else {
          return ''
        }
      },
      ...mapGetters(['locale']),
      title() {
        if (this.locale === 'es') {
          return 'Actividad reciente'
        } else {
          return 'Recent activity'
        }
      },
      description() {
        if (this.locale === 'es') {
          return 'Aquí puedes observar el resumen de las ultimas 50 operaciones realizadas en la plataforma'
        } else {
          return 'Here you can see the summary of the last 50 operations you made on the platform'
        }
      },
      activities() {
        if (this.data.length > 0) {
          const activities = []

          this.data.forEach((activity) => {
            if (this.locale === 'es') {
              const simpleActivity = handleActivityEs(activity)
              simpleActivity.date = new Date(
                simpleActivity.timestamp.split('T')[0]
              ).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
              activities.push(simpleActivity)
            } else if (this.locale === 'en') {
              const simpleActivity = handleActivityEn(activity)
              simpleActivity.date = new Date(
                simpleActivity.timestamp.split('T')[0]
              ).toLocaleDateString('es-VE', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
              activities.push(simpleActivity)
            }
          })
          return activities
        } else {
          return []
        }
      },
    },
    created() {
      this.getActivities()
    },
    methods: {
      getActivities() {
        let userId = ''
        this.$route.params.userId
          ? (userId = this.$route.params.userId)
          : (userId = decryptData(this.$session.get('userId')))
        this.$axios
          .get('activities?order=created_at&by=desc&user_id=' + userId)
          .then((response) => {
            this.data = response.data.data.slice(0, 50)
          })
      },
    },
    beforeDestroy() {
      this.items = null
      delete this.items
    },
  }
</script>
<style lang="scss" scoped>
  .v-list-item__title {
    align-self: center;
    font-size: 0.9rem;
  }
  .v-list-item__subtitle {
    font-size: 0.75rem;
  }
</style>
