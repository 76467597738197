<template>
  <v-card>
    <v-toolbar color="primary" light height="40">
      <v-toolbar-title>{{ title }}</v-toolbar-title></v-toolbar
    >

    <v-timeline class="mx-2" dense>
      <v-timeline-item v-if="loadingMovements">
        <v-row class="mx-0" align="center">
          <v-col cols="12" sm="3" class="">
            <v-skeleton-loader type="chip" style="width: auto; max-width: 80px" />
          </v-col>
          <v-col>
            <v-skeleton-loader type="list-item-two-line" />
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item v-for="(movement, i) in movementsData" :key="i">
        <v-row class="mx-0">
          <v-col cols="12" sm="3" class="pt-0 d-flex align-center">
            <strong>{{ timelineDateString(movement[0].date, 'es-VE') }}</strong>
          </v-col>
          <v-col class="pt-0">
            <v-expansion-panels
              accordion
              flat
              v-if="
                handleTimelineObservation(movement) || handleTimelineMovReason(movement)
              "
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="pa-0">
                  {{ handleTimelineTitle(movement) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  v-html="handleTimelineObservation(movement)"
                  v-if="handleTimelineObservation(movement)"
                />
                <v-expansion-panel-content
                  v-html="handleTimelineMovReason(movement)"
                  v-if="handleTimelineMovReason(movement)"
                />
              </v-expansion-panel>
            </v-expansion-panels>
            <span class="expansion-panel-header--span pa-0" v-else>{{
              handleTimelineTitle(movement)
            }}</span>
          </v-col>
        </v-row>
      </v-timeline-item>
      <!-- <v-timeline-item v-for="(movement, i) in movementsData" :key="i">
        <span slot="opposite">{{ toLocaleDateString(movement.date, 'es-VE') }}</span>

        <v-card class="elevation-2 ma-1">
          <v-card-title class="pa-2" v-if="movement.movement_reason_id === 3" />
          <v-card-title class="pa-2" v-if="movement.movement_reason_id === 2" />
          <v-card-title class="pa-2" v-if="movement.movement_reason_id === 1" />

          <v-card-text class="pa-2 pt-0"> {{ movement.observation }}</v-card-text>
        </v-card>
      </v-timeline-item> -->
    </v-timeline>
  </v-card>
</template>

<script>
  import { toLocaleDateString } from '@/utils/helpers'
  export default {
    name: 'Movements',
    created() {
      this.getMovements()
    },
    data() {
      return { movements: [], movementsData: [], loadingMovements: false }
    },
    props: {
      userId: Number,
      title: {
        type: String,
        default: 'Movimientos',
      },
    },
    watch: {
      userId() {
        this.getMovements()
      },
    },
    methods: {
      getMovements() {
        this.loadingMovements = true
        if (this.userId) {
          this.$axios
            .get(
              `movements?order=date&by=desc&limit=20&user_id=${this.userId}&with[]=position&with[]=movementReason`
            )
            .then((response) => {
              this.movements = response.data
              this.handleMovementsData()
            })
        }
      },
      handleMovementsData() {
        let movementsData = []
        movementsData = this.movements.reduce((groups, item) => {
          const group = groups[item.date] || []
          group.push(item)
          group.sort((a, b) => {
            if (a.entry && !b.entry) {
              return 1
            } else {
              return -1
            }
          })
          groups[item.date] = group
          return groups
        }, {})
        this.movementsData = movementsData
        this.loadingMovements = false
      },
      toLocaleDateString,
      timelineDateString(date, locales) {
        try {
          return new Date(date).toLocaleDateString(locales, {
            day: 'numeric',
            month: 'long',
            timeZone: 'UTC',
          })
        } catch (error) {
          console.warn(error)
          return 'Invalid data'
        }
      },
      handleTimelineTitle(movement) {
        const control = movement[0].type
        let title = ''
        switch (control) {
          case 1:
            title = `Ingreso al cargo ${movement[0].position.name}`
            break
          case 2:
            title = `Transferencia del cargo
            "${movement[0].position.name}" a "${movement[1].position.name}"`
            break
          case 3:
            title = `Ascenso del cargo "${movement[0].position.name}" a "${movement[1].position.name}"`
            break
          case 4:
            title = `Ingreso al cargo ${movement[0].position.name}`
            break

          default:
            title = ''
            break
        }
        return title
      },
      handleTimelineObservation(movement) {
        const control = movement[0].type
        let observation = ''
        switch (control) {
          case 1:
            observation = `${
              movement[0].observation
                ? `<span>Observación: ${movement[0].observation}</span>`
                : ''
            }`
            break
          case 2:
            observation = `${
              movement[1].observation
                ? `<span>Observación: ${movement[1].observation}</span>`
                : ''
            }`
            break
          case 3:
            observation = `${
              movement[1].observation
                ? `<span>Observación: ${movement[1].observation}</span>`
                : ''
            }`
            break
          case 4:
            observation = `${
              movement[0].observation
                ? `<span>Observación: ${movement[0].observation}</span>`
                : ''
            }`
            break
        }
        return observation
      },
      handleTimelineMovReason(movement) {
        const control = movement[0].type
        let movReason = ''
        switch (control) {
          case 1:
            movReason = `${
              movement[0].movemment_reason
                ? `<span>Razón: ${movement[0].movemment_reason.description}</span>`
                : ''
            }`
            break
          case 2:
            movReason = `${
              movement[1].movemment_reason
                ? `<span>Razón: ${movement[1].movemment_reason.description}</span>`
                : ''
            }`
            break
          case 3:
            movReason = `${
              movement[1].movemment_reason
                ? `<span>Razón: ${movement[1].movemment_reason.description}</span>`
                : ''
            }`
            break
          case 4:
            movReason = `${
              movement[0].movemment_reason
                ? `<span>Razón: ${movement[0].movemment_reason.description}</span>`
                : ''
            }`
            break
        }

        return movReason
      },
    },
  }
</script>

<style lang="scss" scoped>
  .expansion-panel-header--span {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 0.9375rem;
    line-height: 1;
    min-height: 48px;
    position: relative;
    width: 100%;
  }
</style>
