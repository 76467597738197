<template>
  <v-img height="150" v-if="cover" :src="cover" class="unset-overflow profile-cover">
    <v-row class="fill-height mx-0 with-radius">
      <v-col cols="12" class="align-self-start">
        <v-avatar size="120">
          <v-img v-if="avatar" :src="avatar" />
        </v-avatar>
        <v-toolbar dense flat class="transparent" dark>
          <v-spacer />
        </v-toolbar>
      </v-col>
    </v-row>
  </v-img>
</template>
<script>
  export default {
    props: {
      avatar: String,
      cover: String,
    },
  }
</script>
